<template>
  <lidaPage
    :columns="tableHead"
    :queryInfo="queryInfo"
    :query="purchasePage"
    :formColumns="formColumns"
    subTitle="总订单作废数"
  >
    <template #documentStatus="scope">
      <div>{{ documentState[scope.row.documentStatus] }}</div>
    </template>
    <template #operation>
      <lida-table-column label="申请作废标识">
        <template #default="scope">
          <span>{{
            scope.row.undoAudit == "IN_PASS"
              ? "申请中（卖方申请）"
              : scope.row.undoAudit == "PASS"
              ? "同意(买方审核) "
              : scope.row.undoAudit=='UN_PASS'?"不同意 (买方审核)":'未申请'
          }}</span>
        </template>
      </lida-table-column>
      <lida-table-column label="操作" width="70" fixed="right">
        <template #default="scope">
          <el-button @click="commands(scope.row)" type="text" v-if="scope.row.undoAuditUserId==userId">审核</el-button>
          <el-button type="text" v-else>无操作</el-button>
        </template>
      </lida-table-column>
    </template>
  </lidaPage>
  <el-dialog v-model="dialogFormVisible">
    <div class="a">
      <div class="a-title">审核订单作废</div>
      <el-form :model="form">
        <el-form-item label="采购单号">
          {{ form.documentNumber }}
        </el-form-item>
        <el-form-item label="审核">
          <el-select
            v-model="form.undoAudit"
            style="width:200px"
          >
            <!-- <el-option label="申请中" value="IN_PASS" /> -->
            <el-option label="不同意" value="UN_PASS" />
            <el-option label="同意" value="PASS" />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      dialogFormVisible: false,
      form: {},
      formLabelWidth: "120px",
      tableHead: [
      
        {
          label: "买方姓名",
          prop: "sellerName",
        },
          {
          label: "供方姓名",
          prop: "buyerName",
        },
        {
          label: "询价单号",
          prop: "inquiryNo",
        },
        {
          label: "采购单号",
          prop: "documentNumber",
        },
        {
          label: "单据状态",
          prop: "documentStatus",
        },
        // {
        // 	label: '申请作废标识',
        // 	prop: 'undoAudit'
        // },
      ],
      queryInfo: {
        auditFlag: "1",
        type:'0'
      },
      userId:''
    };
  },
  created(){
this.userId=JSON.parse(localStorage.getItem('userInfo')).userId
  },
  methods: {
    ...mapActions("mysaleMudule", [
      "purchasePage", //获取订单列表
      'purchaseUndoAudit'
    ]),
    commands(row) {
      //操作的点击事件
      // this.$router.push({
      // 	path: '/mysale/xinOrderDetails',
      // 	query: {
      // 		id: row.documentId, //订单id
      // 		inquiryId: row.inquiryId, //询价单id
      // 	}
      // })
      this.form = { ...row };
      this.dialogFormVisible = true;
    },
    confirm() {
      this.purchaseUndoAudit({ documentId: this.form.documentId,undoAudit:this.form.undoAudit }).then((res) => {
        if (res.data.code=='0') {
            this.dialogFormVisible = false;
            // window.location.reload()
            this.$message({
          message: '审核成功',
          type: 'success'
        });
        }
      });
    },
  },
  computed: {
    ...mapState("purchaseMudule", [
      "documentState",
      "orderStatus", //订单的筛选状态
    ]),
    formColumns() {
      return [
        // {type:'date',prop:'time',label:'创建日期'},
        { type: "input", prop: "wd", label: "综合搜索" },
        {
          type: "select",
          prop: "documentStatus",
          label: "订单状态",
          options: this.orderStatus,
          optionsLabel: "title",
          optionsValue: "type",
        },
      ];
    },
  },
};
</script>
<style lang="less" scoped>
.line {
  background: #f2f5fa;
  height: 10px;
  width: calc(100% + 20px);
  position: relative;
  left: -10px;
}
.a {
  padding: 20px;

  .a-title {
    padding-bottom: 20px;
    font-weight: bold;
  }

  .a-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
}
</style>
